import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5e83e96c&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&rel=stylesheet%2Fscss&lang=scss&"
import style1 from "./index.vue?vue&type=style&index=1&id=5e83e96c&rel=stylesheet%2Fscss&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e83e96c",
  null
  
)

component.options.__file = "index.vue"
export default component.exports